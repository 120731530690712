 <template>
  <div class="vista-escritorio">
    <Cabecera/>
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>{{ eleccion }} - <span><strong>Sección casilla</strong></span></h2>

            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`">
                  Baja California Sur
                </router-link>
              </strong> /
              <span v-if="eleccion !== 'A'">Distrito</span>
              <span v-else>Municipio</span>
              {{ distrito }} / Seccion {{ seccion }} / Básica
            </h4>

            <p>
              Cantidad de votos en cada una de las casillas de esta Sección,
              conforme a la información de las Actas de Escrutinio y Cómputo
            </p>

            <h3>Votos en Actas Contabilizadas</h3>

            <div class="grilla-estado-acta mb-4">
              <div class="acta-digitalizada">
                <p class="small font-weight-bold mb-1">
                  Acta digitalizada:
                </p>
                <button class="btn btn-primary w-100">
                  Ver acta
                </button>
              </div>
              <div class="procedencia">
                <procedencia-acta />
              </div>
            </div>

            <div class="row align-items-end">
              <div
                class="col-12 col-md-6 col-lg-3 col-xl-2 mb-4"
                v-for="(candidato, index) in detalle.votosCandidatura"
                :key="index"
              >
                <TarjetaResumenVotacionCandidato :candidato="candidato" />
              </div>
            </div>

            <div class="row px-3">
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div class="grilla-suma">
                  <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      {{ resumen.total_votos_acumulados_numero }} <br />
                      <span class="porcentaje">
                        {{ resumen.total_votos_acumulados_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumen.total_votos_candidatura_noregistrada_numero }} <br />
                      <span class="porcentaje">
                        {{ resumen.total_votos_candidatura_noregistrada_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                      Nulos <br />
                      {{ resumen.total_votos_nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumen.total_votos_nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumen.total_votos_general_numero  }}</big> <br />
                      <span class="porcentaje">
                        {{resumen.total_votos_general_porcentaje}}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-3 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Observaciones en Acta
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ParticipacionCiudadana :esCasilla="true" :estadisticas="detalle.estadisticas" />
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ObservacionesActas />
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos jurídicos.
        </p>
      </div>
    </Cuerpo>
    <pie-de-pagina />
    <compartir />
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera'
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo'
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue'
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas'
import TarjetaResumenVotacionCandidato from '@/components/Escritorio/componentes/TarjetaResumenVotacionCandidato.vue'
import ParticipacionCiudadana from '@/components/Escritorio/componentes/ParticipacionCiudadana.vue'
import Compartir from '@/components/Compartir.vue'
import PieDePagina from '../../componentes/PieDePagina.vue'
import ProcedenciaActa from '@/components/ProcedenciaActa.vue'

export default {
  name: 'DetalleDeLaCasillaEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    EstadisticaEntidad,
    ObservacionesActas,
    TarjetaResumenVotacionCandidato,
    ParticipacionCiudadana,
    Compartir,
    PieDePagina,
    ProcedenciaActa
  },
  computed: {
    eleccion () {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura'
      } else if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones'
      } else if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos'
      }
    },
    distrito () {
      return this.$route.params.distrito
    },
    seccion () {
      return this.$route.params.seccion
    },
    detalle () {
      /**
       * Aqui debemos de llamar al distrito correspondiente acorde a las variables "Entidad" y "Distrito",
       * por lo pronto quedará estático con lo que tenemos en el JSON que nos dio el INE
       */

      return this.$store.state.casilla
    },
    resumen(){
      return this.$store.state.gdResumenNivelSeccion
      .distritos[this.$route.params.distrito - 1].datos_secciones
      .filter((seccion) =>  parseInt(seccion.seccion_id) ==  parseInt(this.$route.params.seccion))
    },
    colores () {
      return obtenerColoresDeAvatar(this)
    },
  },
  methods: {
    seleccionarCandidato(index) {
      // console.log(this.$store.state.distritos.uno.votosCandidatura[index])
      this.$store.state.distritos.uno.votosCandidatura[index].seleccionado = !this.$store.state.distritos.uno.votosCandidatura[index].seleccionado
    }
  }
}
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  padding: 15px;
  border: 1px solid #582D73;
  height: 25vh;
  .grilla-datos-candidatura {
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #D2127E;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #582D73;
  border-color: transparent;
}

.seleccionado {
  background-color: lighten(#582D73, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}

.tabla-distribucion-votos {
  tbody {
    tr {
      vertical-align: top;
      td {
        svg {
          max-width: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.detalle-por-casilla {
  table {
    thead {
      tr {
        background-color:#582D73;
        color: #FFF;

        th {
          vertical-align: middle;
          img {
            max-width: 35px;
          }
        }
      }
    }
  }
}

.participacion-ciudadana {
  table {
    thead {
      background-color: #DDDDDD;
    }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #F2EFEF;
        }
      }
    }
  }
}

.grilla-estado-acta {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }
}
</style>
