<template>
  <div class="procedencias-actas">
    <p class="small font-weight-bold">
      Imagen procedente de:
    </p>

    <div class="procedencias mb-3">
      
      <template v-if="!extranjero">
        <div class="terminado float-left mr-3 mb-3">
          <font-awesome-icon
            :icon="['far', 'file']"
            class="fa-2x mr-2"
          ></font-awesome-icon>
          <font-awesome-icon
            icon="print"
            class="fa-2x mr-2"
          ></font-awesome-icon>
          Escáner
        </div>
        <div class="terminado float-left mr-3 mb-3">
          <font-awesome-icon
            :icon="['far', 'file']"
            class="fa-2x mr-2"
          ></font-awesome-icon>
          <font-awesome-icon
            icon="mobile-alt"
            class="fa-2x mr-2"
          ></font-awesome-icon>
          Móvil
        </div>
        
      </template>
      <template v-else>
        <div class="terminado float-left mr-3 mb-3">
          <font-awesome-icon
            :icon="['far', 'file']"
            class="fa-2x mr-2"
          ></font-awesome-icon>
          <span>Voto Total</span>
        </div>
      </template>

      <div class="proceso float-left mr-3 mb-3">
        <font-awesome-icon
          :icon="['far', 'file']"
          class="fa-2x mr-2"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="hourglass"
          class="fa-2x mr-2"
        ></font-awesome-icon>
        Acta en proceso
      </div>

      <div class="pt-1 mt-1">
        <div class="contabilizada d-flex align-items-center float-left mr-3 ">        
        <div>
          <img src="@/assets/contabilizada.png" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#4099a0" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>         -->
        </div>
        <div class="pl-2">
          Contabilizada
        </div>
      </div>

      <div class="nocontabilizada d-flex align-items-center float-left mr-3  ">   
        <div>
          <img src="@/assets/nocontabilizada.png" alt="">

          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#D22A7E" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>         -->

        </div>
        <div class="pl-2">
          No  contabilizada
        </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcedenciaActa',
  computed: {
    extranjero() {
      return this.$route.name == "VotoEnElExtranjero" ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.procedencias {
  height: 35px;
  display: block;
  padding-top: 7px;
.contabilizada{
  color:#4099A0 ;
  img{
    width: 20px;
    height: 20px;
  }
}
.nocontabilizada{
  color: #D22A7E;
  img{
    width: 20px;
    height: 20px;
  }
}
  .proceso {
    color: #858585;
    border-left: 2px solid #707070;
    border-right: 2px solid #707070;
    padding: 0 10px;
  }
  .terminado {
    color: #6F65A3;
  }
}
</style>