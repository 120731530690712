<template>
  <div class="row ObservacionesActas py-3">
    <div class="col-12">
      <h5 class="font-weight-bold mb-3 d-lg-none">
        Observaciones en Acta
      </h5>

      <h3 class="font-weight-bold mb-5 d-lg-block d-none">
        Observaciones en Acta
      </h3>

      <div class="grilla mt-5">
        <div>
          <p class="titulo titulo-uno">
            <b>
              Contabilizadas
            </b>
          </p>
          <p class="small">
            Algunas Actas pueden presentar incosistencias,
            pero tienen información que permite contabilizarals:
          </p>
          <p class="font-weight-bold mb-0 small">
            Ilegible
          </p>
          <p class="small">
            La cantidad de votos en algún campo del Acta PREP
            no puede leerse, tanto en letra como en número.
          </p>

          <p class="font-weight-bold mb-0 small">
            Sin dato
          </p>
          <p class="small">
            La cantidad de votos no fue asentada en el Acta PREP
            ni en letra ni en número en algún campo del Acta PREP.
          </p>

          <!-- <p class="font-weight-bold mb-0 small">
            Identificación por Proceso Técnico Operativo
          </p>
          <p class="small">
            Los datos del Acta PREP no se identificaron y se solicitó
            verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
          </p> -->

          <div style="margin-top: 3rem;" v-if="anchoPantalla >= 992">
            <p class="titulo titulo-tres">
              <b>
                No identificadas - <small>Fuera del catálogo</small>
              </b>
            </p>

            <p class="small">
              No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
              aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
              sus datos de identificación. Para fines de publicación, el Acta se muestra al
              final del listado de Actas aprobadas en la Base de Datos.
            </p>
          </div>
        </div>

        <div>
          <p class="titulo titulo-dos">
            <b>
              No Contabilizadas
            </b>
          </p>

          <p class="font-weight-bold mb-0 small">
            Excede Lista Nominal
          </p>
          <p class="small">
            La suma de todos los votos en el Acta PREP, excede el número de ciudadanas
            y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el
            número de votos emitidos por los representantes de los Partidos Políticos y,
            en su caso Candidaturas Independientes.
          </p>

          <p class="font-weight-bold mb-0 small">
            Sin acta
          </p>
          <p class="small">
            El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio
            y Transmisión de Datos (CATD), por algunas de las siguientes causas:
          </p>

          <ol class="small">
            <li>Sin Acta por paquete no entregado.</li>
            <li>Sin Acta por casilla no instalada.</li>
            <li>Sin Acta por paquete entregado sin Bolsa PREP.</li>
          </ol>

          <p class="font-weight-bold mb-0 small">
            Todos los campos son ilegibles
          </p>
          <p class="small">
            Todos los campos del Acta PREP correspondientes al número de votos,
            son ilegibles tanto en letra como en número.
          </p>

          <!-- <p class="font-weight-bold mb-0 small">
            Todos los campos vacíos
          </p>
          <p class="small">
            Todos los campos del Acta PREP correspondientes al número de votos,
            se encuentran vacíos tanto en letra como en número.
          </p> -->
        </div>

        <!-- <div v-if="anchoPantalla < 992">
          <p class="titulo titulo-tres">
            <b>
              No identificadas - <small>Fuera del catálogo</small>
            </b>
          </p>

          <p class="small">
            No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
            aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
            sus datos de identificación. Para fines de publicación, el Acta se muestra al
            final del listado de Actas aprobadas en la Base de Datos.
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObservacionesActas',
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
};
</script>

<style lang="scss" scoped>
.ObservacionesActas {
  background-color: #f5f5f5;
  @media (min-width: 992px) {
    background-color: #fff;
    margin: 0px;
  }
}

.titulo {
  font-size: 25px !important;
  font-weight: 600;
  padding-left: 15px;
  border-left: 7px solid;
}

.titulo-uno {
  
  border-color: #4199a0;
  color: #4199a0;
}

.titulo-dos {
  border-color: #d2127e;
  color: #d2127e
}

.titulo-tres {
  border-color: #7a4493;
  color: #7a4493;
  small {
    color: black;
    font-weight: bold;
  }
}

ol {
  margin-left: 30px;
}

.grilla {
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: flex-start;
    gap: 30px;
    max-width: 1000px;
    margin: 0 auto;
  }
}
</style>
